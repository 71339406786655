import * as React from "react";
import Layout from "../components/Layout";
import {Col, Row} from "antd";
import {graphql} from "gatsby";
import styled from "styled-components";
import {columnProps, getPostsFromQuery} from "../helpers";
import {Navigation} from "../components/Navigation";

const Wrapper = styled.div`
  .color-background {
    height: 100%;
  }
  .container:last-child {
    padding-bottom: 5rem;
  }
`;

const Quote = styled.div`
    margin: 0;
    padding: 15px;
    background: #eee;
    border-radius: 5px;
`;

const OpenSourceCardLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const OpenSource = ({data}) => {
  const posts = getPostsFromQuery(data);
  return (
    <Layout>
      <Wrapper>
        <div className="white-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <Navigation/>
              <h1>My interests</h1>
              <p>I’m interested in the fundamental mechanisms underlying intelligence, understanding, and thought.
                </p><p>
                Ever since I was a little kid, I've been fascinated by the idea that it might be possible to create
                machines that can think and learn like people.
                Creating such machines will have a transformative impact on the world, so I'm also interested in how we
                can reduce the chances of existential risks and mitigate the negative side effects of technology.</p>
              <p>For fun, I enjoy reading (especially short speculative fiction) and playing (and creating) games.</p>
              <h1>Current work</h1>
              <p>I co-founded <a href={"https://imbue.com/"}>Imbue</a> (formerly Generally Intelligent).
                Our mission is to create human-like intelligence and make it safely accessible in order to foster a more
                abundant, unconstrained, and equitable society.
                </p><p>
                I also invest in other founders via my fund, <a href={"https://outsetcapital.com/"}>Outset Capital</a>.
                I love meeting like-minded founders, scientists, and others who are passionate about discovering,
                creating, and building a better world.</p>
              <p>If any of this resonates with you, feel free to <a href={"mailto:josh@generallyintelligent.ai"}>reach out</a>!</p>
              <h1>Past work</h1>
              <p>In the past, I've published machine learning papers as an academic researcher (under <a href={"https://www.sci.pitt.edu/people/rebecca-hwa"}>Rebecca Hwa</a>); founded
                an AI recruiting company that went through <a href={"https://www.ycombinator.com/"}>YC</a> and a 3D injection molding software company that was
                acquired; helped build <a href={"https://www.wsj.com/articles/addepar-valued-at-2-17-billion-as-investors-bet-on-wealth-management-tech-11623756600"}>Addepar</a> as an early engineer; and served as a <a href={"https://thielfellowship.org/"}>Thiel Fellow</a> mentor.
                </p><p>
                I started programming as a kid and built my own computer games, and began working professionally as a
                software engineer in high school.
              </p><p>
              I also helped create <a href={"https://archive.house/"}>the Archive</a>, a co-living house in SF featured on the <a
                  href={"https://www.today.com/video/meet-the-tech-ceo-who-chooses-to-live-with-15-roommates-1342712387551"}>Today
                  Show</a>.</p>

              <h1>Quotes I enjoy</h1>
              <Quote>"It is possible to think of engineering as a kind of poetry... A poet marshals the words and phrases and stanzas for the purpose of moving the listener’s heart; an engineer marshals components and devices and effects for the purpose of changing the world."</Quote>
              <p>- Ken Liu, <i>The Wall of Storms</i></p>
              <Quote>“A complex system that works is invariably found to have evolved from a simple system that worked. A complex system designed from scratch never works and cannot be patched up to make it work. You have to start over, beginning with a working simple system.”</Quote>
              <p>- John Gall</p>
              <Quote>“One does not accumulate but eliminate. It is not daily increase but daily decrease. The height of cultivation always runs to simplicity.”</Quote>
              <p>- Bruce Lee</p>
              <Quote>“[On a battlefield,] out of every one hundred men, ten shouldn’t even be there, eighty are just targets, nine are the real fighters, and we are lucky to have them, for they make the battle. Ah, but the one, one is a warrior...”</Quote>
              <p>- Heraclitus</p>
              <Quote>"If you find yourself in a fair fight, you didn't plan your mission properly."</Quote>
              <p>- Colonel David Hackworth</p>
              <Quote>“Give me six hours to chop down a tree and I’ll spend the first four sharpening the axe.”</Quote>
              <p>- Abraham Lincoln</p>
              <Quote>"Education is an admirable thing, but it is well to remember from time to time that nothing that is worth knowing can be taught."</Quote>
              <p>- Oscar Wilde</p>
              <Quote>"If you take man as he really is, we make him worse... If we overestimate man... we promote him to what he really can be."</Quote>
              <p>- Viktor Frankl</p>
              <Quote>"A human being should be able to change a diaper, plan an invasion, butcher a hog, conn a ship, design a building, write a sonnet, balance accounts, build a wall, set a bone, comfort the dying, take orders, give orders, cooperate, act alone, solve equations, analyze a new problem, pitch manure, program a computer, cook a tasty meal, fight efficiently, die gallantly. Specialization is for insects."</Quote>
              <p>- Robert A. Heinlein, Time Enough for Love</p>

            </Col>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/open-source/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          body
          fields {
            slug
          }
          frontmatter {
            date
            tags
            snippet
            title
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default OpenSource;
